<template>
    <div class="auth-layout">
        <div class="personal-auth-title">抱歉，您好像还没满足我们认证的基本条件噢，请您继续努力吧！</div>
        <div class="personal-auth-progress">
            <div class="condition-item">
                <img src="/static/images/img-personal-auth01.png" alt="">
                <div class="title">绑定手机用户</div>
            </div>
            <div class="condition-item" v-if="condition.auth_status == 0">
                <img src="/static/images/img-personal-auth02.png" alt="">
                <div class="title">提交实名认证</div>
                <span style="cursor:pointer;" @click="onPersonalAuth">去认证></span>
            </div>
            <div class="condition-item" v-if="condition.auth_status == 1">
                <img src="/static/images/img-personal-authed02.png" alt="">
                <div class="title already">提交实名认证</div>
            </div>
            <div class="condition-item" v-if="condition.fans == 0">
                <img src="/static/images/img-personal-auth03.png" alt="">
                <div class="title">粉丝累计≥1万</div>
            </div>
            <div class="condition-item" v-if="condition.fans == 1">
                <img src="/static/images/img-personal-authed03.png" alt="">
                <div class="title already">粉丝累计≥1万</div>
            </div>
            <div class="condition-item" v-if="condition.doc == 0">
                <img src="/static/images/img-personal-auth04.png" alt="">
                <div class="title">投稿数量≥10个</div>
            </div>
            <div class="condition-item" v-if="condition.doc == 1">
                <img src="/static/images/img-personal-authed04.png" alt="">
                <div class="title already">投稿数量≥10个</div>
            </div>
        </div>
        <div class="btn-personal-auth" :class="isAllowAuth ? 'allow' : ''" disabled="true">立即认证</div>
    </div>
</template>
<script>
import user from "@/api/user";
export default{
    name:'personalUnmet',
    components: {},
    data() {
    return { 
        isAllowAuth:false,
        condition:{
            auth_status: 0,
            doc: 0,
            fans: 0,
            mobile: 0,
        }
    }
  },
  created() {
    this.canBeAuth()
  },
  methods: {
    onPersonalAuth(){
        this.$router.push({
            name:'personal_auth'
        })
    },
    // 是否满足个人加V条件
    canBeAuth(){   
        user.getCanBeAuth().then(res => {
            this.condition = res.data
            if(res.data.mobile == 1 && res.data.auth_status == 1 && res.data.fans == 1 && res.data.doc == 1){
                this.isAllowAuth = true
            }else{
                this.isAllowAuth = false
            }
        });
    }
  }
}
</script>
<style lang="scss" scoped>
.auth-layout{padding:60px 0 80px;background:#fff;font-family: Source Han Sans;}
.personal-auth-title{height: 26px;line-height: 26px;font-weight: bold;font-size: 20px;color: #333333;text-align: center;}
.personal-auth-progress{width:1440px;margin:70px auto 80px; display:flex;align-items:center; justify-content: space-between;
    .condition-item{width:130px;display: flex;flex-direction: column;align-items: center;justify-content: center;
        img{ display:block;width: 120px;height: 120px;margin-bottom:10px;}
        .title{line-height: 22px;font-size: 18px;text-align: center;margin-bottom:10px;color:#999;
            &.already{color:#333333;}
        }
        span{font-size: 18px;color: #0073FF;}
    }
}
.btn-personal-auth{width: 152px;height: 48px;line-height:48px;margin:0 auto;border-radius: 6px;background: rgba(0, 0, 0, 0.05);text-align: center;color: #CCCCCC;font-size: 18px;cursor: not-allowed;
    &.allow{cursor: pointer;background: #000000;color: #C1F421;}
}
</style>